<template>
	<div
		class="full-height"
	>
		<h6>{{ program.name }}</h6>

		<Search
			v-if="search"
			class="mt-10 box"
			:search="search"
			:option="search_option"
			:codes="codes"
			:user="user"

			@reset="reset"
			@click="getSearch(1)"
			@toItem="toItem"
			@setSearchDateType="setSearchDateType"
			@agency="setAgency"
		>
			<select
				v-if="is_master"
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.viewGroup"
				@change="getSearch(1)"
			>
				<option value="">구분</option>
				<option
					v-for="(add, index) in items_viewGroup"
					:key="'viewGroup_' + index"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
		</Search>

		<div class="mt-10 pa-10 bg-white full-height flex-column overflow-y-auto">

			<div class="justify-space-between">

				<div class="line-height-200">총 <span class="font-weight-bold">{{ search.total_count | makeComma }}</span> 건</div>
				<select
					class="pa-5-10 mr-10 size-px-12"
					v-model="search.size"
					@change="getSearch(1)"
				>
					<option
						v-for="cnt in codes.list_cnt"
						:key="'cnt_' + cnt"
						:value="cnt"
					>{{ cnt }} 건씩 보기</option>
				</select>
			</div>

			<table
				v-if="items.length > 0"
				class="mt-10 table table-even top-line-identify"
			>
				<colgroup>
					<col width="80px" />
					<col width="120px" />
					<col width="120px" v-if="is_master" />
					<col width="auto" />
					<template
						v-if="is_master"
					>
					<col width="100px" />
					<col width="100px" />

					<col width="100px" />
					</template>
					<col width="120px" />
					<col width="180px" />
				</colgroup>
				<thead>
				<tr>
					<th>NO</th>
					<th>등록일</th>
					<th v-if="is_master">구분</th>
					<th>제목</th>
					<template
						v-if="is_master"
					>
					<th>상단고정</th>
					<th>노출여부</th>

					<th>조회수</th>
					</template>
					<th>작성자</th>
					<th>상세</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'list_' + index"
				>
					<td>{{ item.idx}}</td>
					<td>{{ item.regDate | transDate }}</td>
					<td v-if="is_master">{{ item.viewGroupName}}</td>
					<td class="text-left">{{ item.title }}</td>

					<template
						v-if="is_master"
					>
					<td>{{ item.is_notice }}</td>
					<td>{{ item.is_use }}</td>

					<td>{{ item.view }}</td>
					</template>
					<td>{{ item.author }}</td>
					<td>
						<button
							class="bg-identify pa-5-10 size-px-12"
							@click="toDetail(item)"
						>상세보기</button>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center top-line-identify mt-10"
			>
				<div class="text-center">
					<v-icon
						class="size-px-48 color-icon"
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"

				class="mt-auto"
				@click="getSearch"
			></Pagination>
		</div>

	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";

export default {
	name: 'NoticeList',
	components: { Search, Pagination},
	props: ['user', 'codes', 'date', 'Axios', 'rules', 'query']
	,data: function(){
		return {
			program: {
				name: (this.$route.name == 'NoticeList' ? '결제나라' : '지사') + ' 공지사항'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				page: this.query.page ? this.query.page : 1
				,size: this.query.size ? this.query.size : 10
				,search_type: this.query.search_type ? this.query.search_type : ''
				,keyword: this.query.keyword ? this.query.keyword : ''
				,viewGroup: this.query.viewGroup ? this.query.viewGroup : ''
				,branchWriter: this.query.branchWriter ? this.query.branchWriter : this.user.roleGroup == 'branch' ? '1' : '0'
				,sDate: ''
				,eDate: ''
				,search_date_type: 'month'
			}
			,search_option: {
				search_type: [
					{ name: '제목+내용', column: 'all'}
					, { name: '제목', column: 'title'}
					, { name: '내용', column: 'contents'}
				]
				,is_item: (this.$route.name == 'NoticeList' && this.user.roleGroup == 'admin') || (this.$route.name == 'NoticeBranch' && this.user.roleCode == '1') ? true : false
				,is_search_date_type: true
				,sDate: true
				,eDate: true
			}
			,items: []
			,item: {}
			,items_viewGroup: []
			,items_type: []
		}
	}
	,computed: {
		item_list: function(){

			//let self = this
			return this.items.filter(function(item){
				if(item.isFixed == '0'){
					item.is_notice = '-'
				}else{
					item.is_notice = '고정'
				}

				if(item.exposure == '0'){
					item.is_use = '-'
				}else{
					item.is_use = '노출'
				}

				/*
				for(let i = 0; i < self.items_viewGroup.length; i ++){
					if(item.viewGroup == self.items_viewGroup[i].code){
						item.viewGroupName = self.items_viewGroup[i].name
						break
					}
				}
				 */

				return item
			})
		}
		,is_branch: function(){
			let is = false

			if(this.$route.name == 'NoticeBranch' && this.user.roleId == 'branch'){
				is = true
			}

			return is
		}
		,is_master: function(){
			let is = false

			if(this.user.roleGroup == 'admin'){
				is = true
			}

			return is
		}

		,is_write: function(){
			return (this.$route.name == 'NoticeList' && this.user.roleCode == 0) || (this.$route.name == 'NoticeBranch' && this.user.roleCode == 1) ? true : false
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$emit('onLoading')

				let result = await this.Axios({
					method: 'get'
					, url: 'notice/' + (this.$route.name == 'NoticeList' ? 'admin' : 'branch')
					, data: this.search
				})

				if(result.success){
					this.items = result.data.data
					this.search.total_count = result.data.totalCount
					localStorage.setItem('query', JSON.stringify(this.search))
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,toDetail: function(item){
			if((this.$route.name == 'NoticeList' && this.user.roleGroup == 'admin') || (this.$route.name == 'NoticeBranch' && this.user.roleCode == 1 && item.writerIdx == this.user.idx)){
				this.$emit('push', { name: 'NoticeDetail', params: { idx: item.idx }, not_query: true})
			}else{
				this.$emit('push', { name: 'NoticeView', params: { idx: item.idx }, not_query: true})
			}

		}
		,getSearch: function(page){

			if(page){
				this.search.page = page
			}

			this.getData()
		}
		,toItem: function(){
			this.$emit('push', { name: 'NoticeItem', not_query: true})
		}
		,getViewGroup: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'notice/getViewGroup'
					,data: {
						branchWriter: this.search.branchWriter
					}
				})

				if(result.success){
					this.items_viewGroup = result.data
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,reset: function(){
			this.search = {
				page: 1
				,size: 10
				,search_type: ''
				,keyword: ''
				,viewGroup: 0
			}
			this.getData()
		}
		,getNoticeType: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'notice/getBranchWriter'
				})

				if(result.success){
					this.items_type = result.data
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,setSearchDateType: function(type){
			this.search.search_date_type = type
		}
		,setAgency: function(agency){
			this.search.branchIdx = this.user.branchIdx ? this.user.branchIdx : agency.branchList
			this.search.distributorIdx = this.user.distributorIdx ? this.user.distributorIdx : agency.distributorList
			this.search.agencyIdx = this.user.agencyIdx ? this.user.agencyIdx : agency.agencyList
			this.search.resellerIdx = this.user.resellerIdx ? this.user.resellerIdx : agency.resellerList
			this.getSearch(this.search.page)
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getViewGroup()
		this.getData()
		//this.getNoticeType()
	}
	,watch: {

		'search.search_date_type': {
			immediate: true
			, handler: function(call){
				let today = this.date.getToday('-')
				this.search.eDate = today
				switch(call){
					case 'today':
						this.search.sDate = today

						this.getData()
						break
					case 'weekly':
						this.search.sDate = this.date.getLastDate(today, 7, '-')

						this.getData()
						break
					case 'month':
						this.search.sDate = this.date.getLastDate(today, 30, '-')

						this.getData()
						break
					case '3':
						this.search.sDate = this.date.getLastDate(today, 90, '-')
						break
					case '6':
						this.search.sDate = this.date.getLastDate(today, 180, '-')
						break
				}
			}
		}
	}
}
</script>